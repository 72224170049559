<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      @update:show="toggle(false)"
      class="modal-content-driver"
      :closeOnBackdrop="false"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow class="mt-2">
        <CCol sm="12">
          <CInput
            addLabelClasses="required text-right"
            :placeholder="$t('label.IdNumber')"
            :horizontal="{label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
            :label="$t('label.IdNumber')"
            maxlength="10"
            v-model.trim="$v.DriverCi.$model"
            :invalid-feedback="errorMessage($v.DriverCi)"
            :is-valid="hasError($v.DriverCi)"
          >
            <template #prepend>
              <CSelect
                :value.sync="numberIdLetter"
                :options="ciTypeOptions"
                class="mr-2 mb-0"
                :is-valid="hasError($v.DriverCi)"
              />
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            :label="$t('label.name')"
            :horizontal="{label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
            add-label-classes="required text-right"
            v-uppercase
            :invalid-feedback="errorMessage($v.DriverName)"
            :is-valid="hasError($v.DriverName)"
            v-model.trim="$v.DriverName.$model"
            :value.sync="newDriverName"
            :placeholder="$t('label.driverName')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            :label="$t('label.driverLicense')"
            :horizontal="{label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
            add-label-classes="text-right"
            v-uppercase
            @keypress="onlyNumbersStrict($event)"
            :is-valid="hasError($v.DriverLicense)"
            v-model.trim="$v.DriverLicense.$model"
            :invalid-feedback="errorMessage($v.DriverLicense)"
            :placeholder="$t('label.driverLicense')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <div role="group" class="form-group form-row">
            <label class="col-sm-12 col-lg-3 text-right col-form-label col-form-label-md">{{ $t('label.licenseExpirationDate') }}</label>
            <div class="col-sm-12 col-lg-9 input-group-md d-flex align-items-center">
              <vue-datepicker
                valueType="format"
                :open="openDate"
                @update:open="functionDate($event)"
                @clickoutside="openDate = false"
                class="vue-datepicker-drive:focus" 
                @select="openDate = false"
                @pick="openDate = false"
                @close="openDate = false"
                v-model="LicenseExpirationDate"
                :clearable="false"
                :append-to-body="false"
                :lang="this.$i18n.locale"  
                format="DD/MM/YYYY"
                v-model.trim="$v.LicenseExpirationDate.$model"
                                                
              ><!-- @keypress="formatCharDate($event)" -->
                <template #input>
                  <CInput
                    add-label-classes="text-right"
                    v-uppercase
                    v-model="LicenseExpirationDate"
                    :is-valid="hasError($v.LicenseExpirationDate)"
                    :invalid-feedback="errorMessage($v.LicenseExpirationDate)"
                    v-model.trim="$v.LicenseExpirationDate.$model"                
                    @keypress="formatCharDate($event)"
                    class="mb-0" 
                    :placeholder="$t('label.driverLicenseDateFormat')"                 
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow v-if="editModal">
        <CCol sm="12" class="mt-3">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
            add-label-classes="text-right"
            v-uppercase
            :options="optionsStatus"
            :invalid-feedback="errorMessage($v.Status)"
            :is-valid="statusSelectColor()"
            v-model.trim="$v.Status.$model"
            :value.sync="Status"        
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click="editModal?statusConfirmation(originStatus, Status, submit):submit()" 
                  
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          color="wipe"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';

import choferes from '@/_validations/chofer-vehiculo/chofer';
import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
import moment from 'moment';

function data() {
  return {
    originStatus: '',
    Status: 1,  
    newDriverName: '',
    newDriverCi: '',
    numberIdLetter: 'V',
    modalActive: false,
    loadingOverlay: false,
    loadingTable: false,
    optionsStatus: [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ],
    DriverName: '',
    DriverCi: '',
    DriverLicense: '',
    LicenseExpirationDate: '',
    openDate: false,
    DriverId: '',  
    isSubmit: false,  
  };
}

function selectedStatus(event) {
  this.Status = event.target.value;
}

//### Function para cerrar el modal ###

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

async function submit(){
  //this.Driver= this.$t('label.IdNumber')+' '+this.DriverCi+' - '+this.DriverName;
  try {
    //this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      //this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const DriverJson = {
      DriverCi: (this.numberIdLetter+this.DriverCi),
      DriverName: this.DriverName,
      DriverLicense: this.DriverLicense,
      LicenseExpirationDate: this.LicenseExpirationDate ? moment(this.LicenseExpirationDate,'DD/MM/YYYY').format('YYYY-MM-DD') : null,
    };
    
    if(this.editModal){
      DriverJson.Status= this.Status;
      DriverJson.DriverId= this.DriverId;
    };

    let method = this.editModal? 'PUT':'POST';
    let ruta = this.editModal? 'Driver-update':'Driver-insert';

    await this.$http
      .ejecutar (method, ruta, DriverJson, { root: 'DriverJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          if (this.CallMaster) {
            this.$emit('UpdatedDriver', response.data.data[0]);
          } else {
            this.$emit('refresh-data-table');
          }
          //this.loadingOverlay = false;
          this.isSubmit = false;
          this.toggle(false);
          this.notifySuccess({ text: messageSuccess });
        }
      })
      .catch((e) => {
        //this.loadingOverlay = false;
        this.notifyError({ text: e });
      }).then(() => {
      this.isSubmit = false;
    });
      
  } catch (e) {
    //this.loadingOverlay = false;
    this.isSubmit = false;
    this.notifyError({ text: e });
  }
} 

function resetForm(event) {
  this.DriverId = '';
  this.DriverCi = '';
  this.numberIdLetter = 'V';
  this.DriverName = '';
  this.LicenseExpirationDate = '';
  this.Status = 1;
  this.DriverLicense = '';
  this.openDate = false;
  this.$v.$reset();
  this.$emit('updated-modal', event);
}

function ciTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
    {
      value: "J",
      label: "J",
    },
  ];
}

export default {
  name: 'modalChofer',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    diverSelected: Object,
    CallMaster: Boolean,
  },
  mixins: [GeneralMixin, ChoferVehiculo, ModalMixin, ],
  directives: UpperCase,
 
  methods: {
    functionDate,
    selectedStatus,
    resetForm,
    submit,
    
  },
  computed: {
    ciTypeOptions,
  },
  validations: choferes,
   watch: {
    modal: function(val) {
      this.modalActive = val;
      if(val&&(this.editModal||this.CallMaster)){
        this.DriverId = this.diverSelected.DriverId ?? '';
        this.DriverCi = this.diverSelected.DriverCi ?? '';
        this.DriverName = this.diverSelected.DriverName ?? '';
        this.DriverLicense = this.diverSelected.DriverLicense ?? '';
        this.LicenseExpirationDate = this.diverSelected.LicenseExpirationDate ? 
          moment(this.diverSelected.LicenseExpirationDate,'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        this.originStatus = this.diverSelected.Status === 'ACTIVO' ? 1 : 0;
        this.Status = this.diverSelected.Status === 'ACTIVO' ? 1 : 0;
        this.newDriverName= this.diverSelected.DriverName ?? '';
        this.numberIdLetter = this.diverSelected.DriverCi ? this.diverSelected.DriverCi.charAt(0) : 'V';
        this.DriverCi = this.diverSelected.DriverCi ? this.diverSelected.DriverCi.slice(1) : '';
        this.editModal ? this.$v.$touch() : '';
      }
    },
  },
};
</script>
<style lang="scss">
.modal-content-driver {
  .modal-content {
    width: 90% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}

.mx-datepicker{
    width: 100%;
    
  }
.vue-datepicker-drive {
  
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  } 
}
</style>
